/**
 * Do not edit directly
 * Generated on Mon, 13 Feb 2023 01:10:36 GMT
 */

export const BreakpointXs = '512';
export const BreakpointSm = '768';
export const BreakpointMd = '1024';
export const BreakpointLg = '1280';
export const BreakpointXl = '1440';
export const ColorBlack60 = '#3d4144';
export const ColorBlack80 = '#272e2b';
export const ColorBlack100 = '#1b2023';
export const ColorWhite60 = '#99a0a9';
export const ColorWhite80 = '#f7f7f7';
export const ColorWhite100 = '#fafafa';
export const ColorMain = '#1b2023';
export const ShadowLevel1 = '0 4px 8px 0 rgba(0, 0, 0, 0.10)';
export const ShadowLevel2 = '0 8px 16px 0 rgba(0, 0, 0, 0.10)';
export const ShadowLevel3 = '0 16px 32px 0 rgba(0, 0, 0, 0.10)';
export const ShadowLevel4 = '0 32px 64px 0 rgba(0, 0, 0, 0.10)';
export const SpaceXxxs = '4';
export const SpaceXxs = '6';
export const SpaceXs = '8';
export const SpaceSm = '12';
export const SpaceMd = '20';
export const SpaceLg = '32';
export const SpaceXl = '36';
export const SpaceXxl = '88';
export const SpaceXxxl = '136';
