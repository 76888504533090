/* eslint-disable prefer-destructuring */

import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider as Theme } from 'styled-components';

import * as theme from '../../constants/theme';

const breakpoints = [
  `${theme.BreakpointXs}px`,
  `${theme.BreakpointSm}px`,
  `${theme.BreakpointMd}px`,
  `${theme.BreakpointLg}px`,
  `${theme.BreakpointXl}px`,
];

breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const colors = {
  main: `${theme.ColorMain}`,
  bg100: `${theme.ColorWhite100}`,
  bg80: `${theme.ColorWhite80}`,
  bg60: `${theme.ColorWhite60}`,
  black100: `${theme.ColorBlack100}`,
  black80: `${theme.ColorBlack80}`,
  black60: `${theme.ColorBlack60}`,
  contrast100: `${theme.ColorBlack100}`,
  contrast80: `${theme.ColorBlack80}`,
  contrast60: `${theme.ColorBlack60}`,
  white100: `${theme.ColorWhite100}`,
  white80: `${theme.ColorWhite80}`,
  white60: `${theme.ColorWhite60}`,
};

const shadows = {
  level1: `${theme.ShadowLevel1}`,
  level2: `${theme.ShadowLevel2}`,
  level3: `${theme.ShadowLevel3}`,
  level4: `${theme.ShadowLevel4}`,
};

const sizes = [
  0,
  theme.SpaceXxxs,
  theme.SpaceXxs,
  theme.SpaceXs,
  theme.SpaceSm,
  16,
  theme.SpaceMd,
  theme.SpaceLg,
  theme.SpaceXl,
  theme.SpaceXxl,
  theme.SpaceXxxl,
];

sizes.xxxs = sizes[1];
sizes.xxs = sizes[2];
sizes.xs = sizes[3];
sizes.sm = sizes[4];
sizes.md = sizes[5];
sizes.lg = sizes[6];
sizes.xl = sizes[7];
sizes.xxl = sizes[8];
sizes.xxxl = sizes[9];

function ThemeProvider({ children }) {
  return (
    <Theme theme={{
      breakpoints, colors, shadows, sizes,
    }}
    >
      {children}
    </Theme>
  );
}

ThemeProvider.defaultProps = {
  children: null,
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
